/* input form */
.external-form {
  background-color: var(--gray-100);
  border-radius: 15px;
  margin-top: 5.599em;
  margin-bottom: 5.2345em;
  padding-top: 1.615em;
  width: 27.778em;
  color: var(--blue-900);
}
.external-form hr {
  display: none !important;
}
.external-form .pricesBlock:nth-child(6) {
  padding: 0 !important;
}
.external-form .pricesBlock {
  padding: 0 !important;
  width: 22.778em !important;
}
.external-form .pricesBlock {

  & h4 {
    width: 100% !important;
  }
  & .infoTitle,
  & .priceText {
    width: 50% !important;
  }
}

.catalogTitle {
  font-family: 'Zen Dots' !important;
  font-weight: 400 !important;
  font-size: 1.667rem !important;
  line-height: 1.3617em !important;
  margin: 0 !important;
  margin-left: 1.5em !important;
  font-weight: 400 !important;
  color: var(--blue-second-700) !important;
  display: flex;
  padding: 0 !important;
}

#catalogitemform {
  height: min-content !important;
  display: flex;
  flex-direction: column;
}
.external-form .catalogTitleDiv {
  height: min-content !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
}
.external-form .row {
  --bs-gutter-x: 0 !important;
}
.external-form .mb-5 {
  margin: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
.external-form .pl-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.external-form .priceLine {
  padding: 0 !important;
  width: 100%;
}

.registerFormTitle,
.external-form h4,
.infoTitle,
.priceText {
  font-size: 0.8334rem !important;
  line-height: 1.362em !important;
  width: 27.3312em !important;
  margin-top: 2.1877em !important;
  margin-bottom: 0.9em !important;
  padding-bottom: 0 !important;
}

.form-group,
.form-input-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 22.778em;
  padding: .487em .8em .556em 1.667em !important;
  border-radius: 2.5px;
}
.external-form .form-group {
  margin-bottom: .8334em !important;
}
.form-label,
.form-input__label {
  font-size: .695rem !important;
  color: var(--gray-300) !important;
  margin-bottom: .2em !important;
  line-height: 117.4%;
}
.form-control,
.form-input-container input {
  background-color: transparent !important;
  width: 100% !important;
  padding: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: var(--purple) !important;
  outline: none !important;
  font-size: 1.112rem !important;
  line-height: 1%;
  box-shadow: none !important;
  min-height: max-content !important;
}
.form-input__line {
  /* width: 100%;
  height: 2px;
  background-color: var(--purple); */
}
.external-form .paymentTypeItem,
.form-input__button,
#btn-save-catalogItemRegister {
  font-size: 1.2355rem !important;
  font-weight: 700 !important;
  width: 10.247em !important;
  /* height: 2.75em; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: var(--turquoise-900) !important;
  color: white !important;
  border: 0 !important;
  border-radius: 55px !important;
  outline: none !important;
  cursor: pointer !important;
  padding: .9377em 0 !important;
}
.paymentTypeItemText {
  font-size: 1.2355rem !important;
  margin-left: .5em;
}
.registerpayAction {
  display: flex;
}
.row.pt-4.pb-4.pl-2 > .form-group {
  background-color: transparent !important;
  display: flex;
  align-items: center;
}
.form-group textarea {
  width: 100% !important;
  height: 2em !important;
}

.external-form .form-select {
  box-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important;
  cursor: pointer !important;
  padding: 0 !important;
  color: var(--purple) !important;
}
.external-form .form-select > option {
  background: var(--blue-900) !important;
  color: white !important;
}

.external-form .iconify {
  width: 1.2em;
  height: max-content;
}
/* .external-form option {
  background-color: transparent !important;
} */
/* end input form */

@media screen and (max-width: 1024px) {
  /* Form */
  .external-form {
    width: 33.959em;
  }
  .form-group,
  .form-input-container,
  .pricesBlock {
    width: 31.563em !important;
    padding-top: .2em !important;
    padding-left: 1.042em !important;
    padding-right: 4.896em !important;
    padding-bottom: 0.73em !important;
  }
  .external-form .form-group {
    margin-bottom: .625em !important;
  }

  .catalogTitle {
    font-size: 2.084rem !important;
    margin-left: .75em !important;
  }
  .catalogTitle::before {
    width: 1.046em;
    height: .9em;
    margin-right: .5em;
  }

  .registerFormTitle,
  .external-form h4,
  .infoTitle,
  .priceText,
  .paymentTypeItemText {
    font-size: 1.25rem !important;
    margin-top: .7em !important;
    margin-left: .75em !important;
    width: 25.3312em !important;
  }

  .form-label,
  .form-input__label {
    font-size: 1.25rem !important;
    margin-bottom: -.2em !important;
  }
  .form-control,
  .form-input-container input,
  textarea {
    font-size: 1.146rem !important;
    line-height: inherit !important;
  }
  .external-form .paymentTypeItem,
  .form-input__button {
    font-size: 1.667rem !important;
    width: 7.749em !important;
    height: 2.938em !important;
  }
  .external-form .iconify {
    width: 1.7em;
    height: max-content;
  }
}